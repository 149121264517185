/* eslint-disable no-empty */
import { useState, useEffect } from 'react'
import { message } from 'antd'
import imageCompression from 'browser-image-compression'
import { uploadImageApi, uploadImageWithoutLogoApi } from '../../apis/uploadApis'
import ErrorMessage from '../websiteManager/errorImage'
import { DEFAULT_IMAGE } from '../../constants/constant'

const options = {
  maxSizeMB: 5, // Giới hạn kích thước tối đa của ảnh sau khi nén (5MB ở đây)
  maxWidthOrHeight: 800, // Giới hạn chiều rộng hoặc chiều cao tối đa sau khi nén (800px ở đây)
  useWebWorker: true, // Sử dụng Web Worker để nén ảnh (tùy chọn)
}

const UploadImageComponent = ({
  imageUrl: imageUrlProps, isEditing, setImage, validate, content, name, linkError, minHeight, withoutLogo, withoutCompressed,
}) => {
  const [imageUrl, setImageUrl] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setImageUrl(imageUrlProps)
  }, [imageUrlProps])

  const uploadFile = async (file) => {
    try {
      setLoading(true)
      const formData = new FormData()
      const compressedImage = withoutCompressed ? file : await imageCompression(file, options)
      formData.append('file', compressedImage)

      const response = withoutLogo ? await uploadImageWithoutLogoApi(formData) : await uploadImageApi(formData)
      const url = response?.data?.data
      if (url) {
        setImageUrl(url)
        setImage(url)
        message.success('Upload ảnh thành công!')
      } else {
        message.error('Upload ảnh thất bại!')
      }
    } catch (error) {
      message.error('Upload ảnh thất bại!')
    } finally {
      setLoading(false)
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/heic') {
      message.error('Chỉ chấp nhận file ảnh')
      return
    }
    uploadFile(file)
  }

  const handleDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/heic') {
      message.error('Chỉ chấp nhận file ảnh')
      return
    }

    uploadFile(file)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleClickSelectFile = () => {
    const input = document.getElementById(`select-image-${name}`)
    if (input) {
      input.value = ''
      input.click()
    }
  }

  const onImageError = (e) => {
    e.target.src = linkError || DEFAULT_IMAGE
  }

  return (
    <div key={name}>
      {
      imageUrl
        ? (
          <div className="image-component" style={{ minHeight: minHeight || 288 }}>
            {
              isEditing
              && (
              <div className="choose-image-layer">
                <div className="camera-icon" role="presentation" onClick={handleClickSelectFile}>
                  <img alt="camera-icon" src="/images/common/camera.svg" />
                </div>
                <input
                  id={`select-image-${name}`} type="file"
                  onChange={handleFileChange} style={{ display: 'none' }}
                />
              </div>
              )
            }
            <img style={{ maxHeight: minHeight || 262 }} className="image-comp-main" src={imageUrl} alt="imageUrl" onError={onImageError} />
          </div>
        )
        : (
          <div className="drag-file-comp">
            <div
              className="drag-file"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{ border: !validate ? '1px solid #ff4d4f' : '1px dashed #e8e8e8' }}
            >
              <input
                id={`select-image-${name}`} type="file"
                onChange={handleFileChange} style={{ display: 'none' }}
              />
              {
                content
                  ? (
                    <>
                      <div role="presentation" onClick={handleClickSelectFile}>{content}</div>
                    </>
                  )
                  : (
                    <>
                      <button type="button" className="btn btn-select-file" onClick={handleClickSelectFile}>Select Files</button>
                      <div className="text-drop">
                        <img src="/images/common/upload.svg" alt="upload" />
                        Drop image here...
                      </div>
                    </>
                  )
              }

            </div>
            <ErrorMessage text="Vui lòng tải ảnh lên" isShow={!validate} />

          </div>
        )
    }
    </div>
  )
}

export default UploadImageComponent
