import { CaretRightOutlined, DownOutlined } from '@ant-design/icons'
import {
  Button, Collapse, Drawer, Select, message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import FilterItem from '../../../components/filter/filterItem'
import ExternalAccountApis from '../../../apis/externalAccountApis'

const filterByStatusExternal = [
  {
    key: 'all',
    value: 'Tất cả',
  },
  {
    key: 'active',
    value: 'Hoạt động',
  },
  {
    key: 'suspend',
    value: 'Tạm ngưng',
  },
  {
    key: 'pending',
    value: 'Chờ duyệt',
  },
]

const FilterDrawerAdmin = (props) => {
  const {
    isOpenFilterDrawer,
    closeFilter,
    queryParams,
    onClose,
    roleList,
    setRoleList,
    isKHDN,
  } = props
  const [userStatus, setUserStatus] = useState(queryParams?.status)
  const [roleId, setRoleId] = useState(queryParams?.role_id)
  const [sellerId, setSellerId] = useState(queryParams?.seller_id)
  const [sellerList, setSellerList] = useState([])
  const [listProvince, setListProvince] = useState([])
  const [listDistrict, setListDistrict] = useState([])
  const [listWard, setListWard] = useState([])

  const [provinceID, setProvinceID] = useState([])
  const [districtID, setDistrictID] = useState([])
  const [wardID, setWardID] = useState([])

  const getRoleList = async () => {
    try {
      const res = await ExternalAccountApis.getExternalRoles()
      const mappingData = res?.data?.data?.map?.((item) => ({
        key: item.id,
        value: item.name,
      }))

      setRoleList(mappingData)
    } catch (error) {
      if (error?.response?.data?.statusCode === 403) {
        message.error('Tính năng không khả dụng')
      } else {
        message.error('Error')
      }
    }
  }

  const getSellerList = async () => {
    try {
      const res = await ExternalAccountApis.getSellers({
        status: 'active',
      })
      setSellerList(res?.data?.data?.items)
    } catch (error) {
      if (error?.response?.data?.statusCode === 403) {
        message.error('Tính năng không khả dụng')
      } else {
        message.error('Error')
      }
    }
  }

  useEffect(() => {
    Promise.all([
      // getRoleList(),
      getSellerList(),
    ])
  }, [])

  const generateFilterData = () => ({
    status: userStatus === 'all' ? null : userStatus,
    role_id: roleId,
    seller_id: sellerId,
    province_id: provinceID,
    district_id: districtID,
    ward_id: wardID,
  })

  const clearFilter = () => {
    onClose({})
    setUserStatus(null)
    setRoleId(null)
    setSellerId(null)
  }

  const fetchProvince = async () => {
    const res = await ExternalAccountApis.getProvince()
    setListProvince(res?.data?.data ?? [])
  }

  useEffect(() => {
    fetchProvince()
  }, [])

  const fetchDistrict = async (province) => {
    if (province) {
      const res = await ExternalAccountApis.getDistrict(province)
      setListDistrict(res?.data?.data ?? [])
    }
  }

  const fetchWard = async (province, district) => {
    if (province && district) {
      const res = await ExternalAccountApis.getWard(province, district)
      setListWard(res?.data?.data ?? [])
    }
  }

  useEffect(() => {
    if (isOpenFilterDrawer) {
      setUserStatus(queryParams?.status)
      setRoleId(queryParams?.role_id)
      setSellerId(queryParams?.seller_id)
      setProvinceID(queryParams?.province_id)
      setDistrictID(queryParams?.district_id)
      setWardID(queryParams?.ward_id)
      if (queryParams?.province_id) {
        fetchDistrict(queryParams?.province_id)
      }
      if (queryParams?.province_id && queryParams?.district_id) {
        fetchWard(queryParams?.province_id, queryParams?.district_id)
      }
    }
  }, [isOpenFilterDrawer])

  const handleSelectProvince = (value) => {
    setDistrictID(null)
    setWardID(null)
    setListDistrict([])
    setListWard([])
    setProvinceID(value)
    if (value) {
      fetchDistrict(value)
    }
  }

  const handleSelectDistrict = (value) => {
    setWardID(null)
    setListWard([])
    setDistrictID(value)
    if (provinceID && value) {
      fetchWard(provinceID, value)
    }
  }

  return (
    <Drawer
      title="Chi tiết bộ lọc"
      width={550}
      onClose={closeFilter}
      open={isOpenFilterDrawer}
      className="filter-external-drawer drawer-common"
      extra={(
        <div className="drawer-extra">
          <Button onClick={clearFilter}>Xoá bộ lọc</Button>
          <Button
            size="small"
            type="primary"
            onClick={() => onClose(generateFilterData())}
          >
            Áp dụng
          </Button>
        </div>
      )}
      footer={null}
    >
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={['1', '2', '3', '4']}
      >
        {!isKHDN && (
          <Collapse.Panel header="Theo loại tài khoản" key="1">
            <div className="by-account-type">
              {roleList
                .filter((x) => x.value !== 'Khách hàng doanh nghiệp')
                .map((status, index) => (
                  <FilterItem
                    key={index}
                    handleFilterKey={(data) => setRoleId((prevData) => (prevData === data ? null : data))}
                    isActive={roleId === status?.key}
                    filterName={status?.value}
                    filterKey={status?.key}
                  />
                ))}
            </div>
          </Collapse.Panel>
        )}

        <Collapse.Panel header="Theo trạng thái" key="2">
          <div className="by-status">
            {filterByStatusExternal.map((status, index) => (
              <FilterItem
                handleFilterKey={(data) => setUserStatus((prevData) => (prevData === data ? null : data))}
                key={index}
                isActive={userStatus === status?.key}
                filterName={status?.value}
                filterKey={status?.key}
              />
            ))}
          </div>
        </Collapse.Panel>

        <Collapse.Panel header="Theo seller phụ trách" key="3">
          <div className="seller">
            <p className="seller-title">Tên seller</p>
            <Select
              size="large"
              value={sellerId}
              style={{ width: '100%' }}
              placeholder="Chọn seller phụ trách"
              onChange={(sId) => {
                setSellerId(sId)
              }}
              showSearch
              optionFilterProp="username"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sellerList.map((seller) => (
                <Select.Option key={seller.id} value={seller.id}>
                  {`${seller?.full_name} - ${seller?.username}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Collapse.Panel>

        <Collapse.Panel header="Theo địa chỉ" key="4">
          <div className="seller">
            <p className="seller-title">Tỉnh/ Thành phố</p>
            <Select
              style={{ width: '100%' }}
              placeholder="Chọn Tỉnh/ Thành Phố"
              size="large"
              allowClear
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={provinceID}
              onChange={(value) => handleSelectProvince(value)}
            >
              {(listProvince || []).map((province) => (
                <Select.Option key={province?.id} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="seller">
            <p className="seller-title" style={{ marginTop: '20px' }}>
              Quận/ Huyện
            </p>
            <Select
              style={{ width: '100%' }}
              placeholder="Chọn Quận/ Huyện"
              size="large"
              allowClear
              value={districtID}
              optionFilterProp="name"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(value) => handleSelectDistrict(value)}
              showSearch
            >
              {(listDistrict || []).map((province) => (
                <Select.Option key={province?.id} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="seller">
            <p className="seller-title" style={{ marginTop: '20px' }}>
              Phường /Xã
            </p>
            <Select
              placeholder="Chọn Phường /Xã"
              size="large"
              style={{ width: '100%' }}
              allowClear
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={wardID}
              onChange={(value) => setWardID(value)}
            >
              {(listWard || []).map((province) => (
                <Select.Option key={province?.id} value={province?.id}>
                  {province?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  )
}

export default FilterDrawerAdmin
