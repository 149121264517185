export const firebaseConfig = {
  apiKey: 'AIzaSyBB2v8FPm6abNHES9lqpGoHDl2aHruTvvA',
  authDomain: 'simplus-b9b14.firebaseapp.com',
  projectId: 'simplus-b9b14',
  storageBucket: 'simplus-b9b14.appspot.com',
  messagingSenderId: '206786210282',
  appId: '1:206786210282:web:fa6d3b786817b981606a93',
  measurementId: 'G-816XKLZDHB',
}

export const VALID_API_KEY = 'BPumNX1awqGL5w8WKoDEgYTNpyiVvilZpnP6lkNvdvADwjMq7MRCsBsrG34Jc7XCA0Bo_CZtxO65BtqI9q8k_Tc'
