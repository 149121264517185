/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { onMessage } from 'firebase/messaging'
import { Spin } from 'antd'
import NotificationApis from '../../apis/notificationApis'
import { formatDateNoti } from '../../util'
import { messaging } from '../../firebase/firebaseHelper'

const mailSvg = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM19.6 8.25L13.06 12.34C12.41 12.75 11.59 12.75 10.94 12.34L4.4 8.25C4.15 8.09 4 7.82 4 7.53C4 6.86 4.73 6.46 5.3 6.81L12 11L18.7 6.81C19.27 6.46 20 6.86 20 7.53C20 7.82 19.85 8.09 19.6 8.25Z"
      fill="currentColor"
    />
  </svg>
)

const Notifications = ({ history }) => {
  const [notifications, setNotifications] = useState([])

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenDropdown(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  const getDataListNoti = async () => {
    try {
      setIsLoading(true)
      const res = await NotificationApis.getListNoti({
        order_by: 'created_at',
        sort_by: 'desc',
      })
      const data = res?.data?.data?.items || []
      setNotifications(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const handlePushMessage = () => {
    setIsOpenDropdown(true)
    getDataListNoti()
  }

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('Message received: ', payload)
      handlePushMessage()
    })
  }, [])

  useEffect(() => {
    if (isOpenDropdown) {
      getDataListNoti()
    }
  }, [isOpenDropdown])

  const handleItemClick = (item) => {
    const link = item?.data?.link
    setTimeout(() => {
      setIsOpenDropdown(false)
    }, 100)
    if (!link) return
    history.push(link)
  }

  return (
    <>
      <div
        className="header-avt header-noti email-header"
        role="presentation"
        onClick={() => setIsOpenDropdown(true)}
        tabIndex={0}
        ref={dropdownRef}
      >
        <div
          className="mail-svg"
          style={{ color: isOpenDropdown ? '#00BA34' : '#969696' }}
        >
          {mailSvg}
        </div>
        <div
          className="account-dd noti-dd"
          style={{
            display: isOpenDropdown ? 'block' : 'none',
            overflow: 'auto',
          }}
        >
          <div className="noti-header">Trung tâm thông báo</div>
          <div className="noti-body">
            {isLoading ? (
              <div className="noti-loading">
                <Spin />
              </div>
            ) : (
              notifications.map((item, index) => (
                <div
                  key={index}
                  className="noti-item"
                  role="presentation"
                  onClick={() => {
                    handleItemClick(item)
                  }}
                >
                  <div className="noti-bell">
                    <img
                      src="/images/common/notifications_active.svg"
                      alt="noti"
                    />
                  </div>
                  <div className="noti-main">
                    <div className="noti-title">{item?.title}</div>
                    <div className="noti-desc">{item?.description}</div>
                    <div className="noti-time">
                      {formatDateNoti(item?.created_at)}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Notifications)
