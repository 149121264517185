const getErrorMessagesAccountPage = (error) => {
  if (error?.includes('E10043')) {
    return 'Email đã tồn tại'
  }

  if (error?.includes('E10044')) {
    return 'Số CMND/CCCD đã tồn tại'
  }

  if (error?.includes('E10045')) {
    return 'Tên đăng nhập đã tồn tại'
  }

  if (error?.includes('E10046')) {
    return 'Tạo tài khoản thất bại'
  }

  if (error?.includes('E10056')) {
    return 'Số điện thoại đã tồn tại'
  }

  if (error[0]?.includes('avatar_url')) {
    return 'Vui lòng tải lên ảnh đại diện!'
  }

  return null
}

export { getErrorMessagesAccountPage }
