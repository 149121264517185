import Axios from '../services/Axios'

const NotificationApis = {
  addRegisterToken(data) {
    return Axios.getInstance().post('/api/v1/notifications/register-token', data)
  },
  removeRegisterToken(data) {
    return Axios.getInstance().post('/api/v1/notifications/unregister-token', data)
  },
  getListNoti(params) {
    return Axios.getInstance().get('/api/v1/notifications', { params })
  },
}

export default NotificationApis
