import Axios from '../services/Axios'

const ExternalAccountApis = {
  getExternalAccount(params) {
    return Axios.getInstance().get('/api/v1/accounts/external-accounts', { params })
  },

  getExternalRoles() {
    return Axios.getInstance().get('/api/v1/accounts/external-roles')
  },

  getSellers(params) {
    return Axios.getInstance().get('/api/v1/accounts/sellers', { params })
  },

  createExternalAccount(data) {
    return Axios.getInstance().post('/api/v1/accounts/external-account', data)
  },

  editExternalAccount(data, id) {
    return Axios.getInstance().put(`/api/v1/accounts/external_account/${id}`, data)
  },

  getDetailExternalAccount(id) {
    return Axios.getInstance().get(`/api/v1/accounts/external_account/${id}`)
  },

  activeExternalAccount(id) {
    return Axios.getInstance().put(`/api/v1/accounts/external-account/${id}/activate`)
  },

  suspendExternalAccount(id, data) {
    return Axios.getInstance().put(`/api/v1/accounts/external-account/${id}/deactivate`, data)
  },

  approveExternalAccount(id) {
    return Axios.getInstance().put(`/api/v1/accounts/external-account/${id}/approve`)
  },

  rejectExternalAccount(id, data) {
    return Axios.getInstance().put(`/api/v1/accounts/external-account/${id}/reject`, data)
  },

  getProvince() {
    return Axios.getInstance().get('/api/v1/provines')
  },

  getDistrict(provinceID) {
    return Axios.getInstance().get(`/api/v1/provines/${provinceID}/districts`)
  },

  getWard(provinceID, districtID) {
    return Axios.getInstance().get(`/api/v1/provines/${provinceID}/districts/${districtID}/wards`)
  },

  deleteAccount(id) {
    return Axios.getInstance().delete(`/api/v1/accounts/external-account/${id}`)
  },

  deleteAccountPartner(id) {
    return Axios.getInstance().delete(`/api/v1/partners/${id}`)
  },
}

export default ExternalAccountApis
