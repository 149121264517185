import React, { useState } from 'react'
import { Modal, Input, message } from 'antd'
import { activatePartnerApi, deactivatePartnerApi } from '../../../../apis/partnerApis'

function ModalChangeStatusPartner(props) {
  const {
    isOpen, setIsOpen, accountStatus, representativeId, getDetailPartner, getListPartner, getListSeller,
  } = props

  const [reason, setReason] = useState('')

  const handleCloseModal = () => {
    setIsOpen(false)
    setReason('')
  }

  const activatePartner = async () => {
    try {
      await activatePartnerApi(representativeId)
      message.success('Kích hoạt tài khoản thành công')
      await Promise.all([getDetailPartner(), getListPartner(), getListSeller()])
    } catch (e) {
      message.error('Có lỗi xảy ra vui lòng thử lại sau')
    } finally {
      handleCloseModal()
    }
  }

  const suspendPartner = async () => {
    try {
      await deactivatePartnerApi(representativeId, {
        note: reason,
      })
      setReason('')
      message.success('Vô hiệu hoá tài khoản thành công')
      await Promise.all([getDetailPartner(), getListPartner(), getListSeller()])
    } catch (e) {
      message.error('Có lỗi xảy ra vui lòng thử lại sau')
    } finally {
      handleCloseModal()
    }
  }

  return (
    <Modal
      open={isOpen}
      title={accountStatus === 'active' ? 'Bạn muốn vô hiệu hoá tài khoản?' : 'Bạn muốn kích hoạt tài khoản?'}
      okButtonProps={{
        className: accountStatus === 'active' ? 'btn-red' : 'btn-green',
        disabled: accountStatus === 'active' ? !reason : false, // Nếu action là vô hiệu hoá thì disable nút xác nhận khi chưa nhập lý do
      }}
      cancelButtonProps={{
        size: 'large',
      }}
      centered
      onCancel={handleCloseModal}
      onOk={accountStatus === 'active' ? suspendPartner : activatePartner}
      okText="Xác nhận"
      cancelText="Hủy"
    >
      {
        accountStatus === 'active' ? <p>Bạn có chắc muốn vô hiệu hoá tài khoản này</p> : <p>Bạn có chắc muốn kích hoạt tài khoản này</p>
      }
      {
        accountStatus === 'active' && <Input.TextArea value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Nhập lý do vô hiệu hoá" />
      }
    </Modal>
  )
}

export default ModalChangeStatusPartner
