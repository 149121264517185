export const mappingErrorMessage = (errorMessage) => {
  // CREATE_PARTNER_FAILED: "E10099: Create partner failed",
  if (errorMessage?.includes('E10099')) {
    return 'Tạo tài khoản thất bại'
  }

  // PARTNER_EXISTED: "E10100: Partner exist",
  if (errorMessage?.includes('E10100')) {
    return 'Tài khoản đã tồn tại'
  }
  // SELLER_PARTNER_EXISTED: "E10101: Partner seller exist",
  if (errorMessage?.includes('E10101')) {
    return 'Tài khoản đã tồn tại'
  }
  // INCORRECT_REPRESENTATIVE_PARTNER_ACCOUNT: 'E10103: Incorrect representative partner account',
  if (errorMessage?.includes('E10103')) {
    return 'Tài khoản đại diện không đúng'
  }
  // INCORRECT_PARTNER_SELLER_ACCOUNT: 'E10102: Incorrect partner account',
  if (errorMessage?.includes('E10102')) {
    return 'Tài khoản đại diện không đúng'
  }
  // INACTIVE_PARTNER: "E10103: Partner not active",
  if (errorMessage?.includes('E10103')) {
    return 'Tài khoản chưa được kích hoạt'
  }

  return 'Có lỗi xảy ra, vui lòng thử lại sau'
}
