/* eslint-disable no-param-reassign */
import axios from 'axios'
import { message } from 'antd'
import { ERP_URL } from '../constants/apis'
import { KEYS, get } from '../util/localStorage'

export default class Axios {
  static instance;

  axiosApi;

  constructor(baseURL = ERP_URL) {
    this.axiosApi = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': true,
      },
    })

    this.axiosApi.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Clear storage token and reload the application
          message.error('Hết hạn đăng nhập, vui lòng đăng nhập lại!')
          localStorage.removeItem('TOKEN')
          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
        // if (error.response && error.response.status === 403) {
        //   message.error('Tài khoản bị vô hiệu hóa')
        //   localStorage.removeItem('TOKEN')
        //   setTimeout(() => {
        //     window.location.reload()
        //   }, 500)
        // }
        return Promise.reject(error)
      },
    )
    const token = get(KEYS.TOKEN)
    if (token) {
      this.axiosApi.interceptors.request.use(
        (config) => {
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
          return config
        },
        (error) => {
          Promise.reject(error)
        },
      )
    }
  }

  static getInstance() {
    this.instance = this.instance ?? new Axios()
    return this.instance
  }

  setToken(token) {
    this.axiosApi.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        Promise.reject(error)
      },
    )
  }

  get(
    url,
    config,
  ) {
    return this.axiosApi.get(url, config)
  }

  post(
    url,
    data,
    config,
  ) {
    return this.axiosApi.post(url, data, config)
  }

  put(
    url,
    data,
    config,
  ) {
    return this.axiosApi.put(url, data, config)
  }

  delete(
    url,
    config,
  ) {
    return this.axiosApi.delete(url, config)
  }
}
