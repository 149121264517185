import React from 'react'
import { Button, message, Modal } from 'antd'
import { resetPassword } from '../../../../apis/authenticationApis'

const TEXT = {
  CONFIRM_RESET_PASSWORD_TITLE: 'Bạn muốn thiết lập lại mật khẩu?',
  RESET_PASSWORD_SUCCESS_TITLE: 'Mật khẩu đã thiết lập lại thành công',
  CONFIRM_RESET_PASSWORD_CONTENT:
    '<p>Mật khẩu của tài khoản được chọn sẽ được thiết lập lại về mật khẩu mặc định</p>',
  RESET_PASSWORD_SUCCESS_CONTENT:
    '<div>Tên tài khoản: <b>{{username}}</b></div><div>Mật khẩu: <b>Password@123</b></div>',
}
function ModalResetPassword(props) {
  const { isOpen, setIsOpen, username } = props

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const resetPasswordFunc = async () => {
    try {
      await resetPassword({
        username,
      })
      setIsOpen(false)
      Modal.success({
        title: (
          <div
            style={{ fontSize: 20 }}
            dangerouslySetInnerHTML={{
              __html: TEXT.RESET_PASSWORD_SUCCESS_TITLE,
            }}
          />
        ),
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: TEXT.RESET_PASSWORD_SUCCESS_CONTENT.replace(
                '{{username}}',
                username,
              ),
            }}
          />
        ),
        width: 456,
        icon: (
          <img
            src="/images/common/check_circle.svg"
            alt="check_circle"
            width={48}
            height={48}
            style={{ marginRight: 16 }}
          />
        ),
        footer: (_, { OkBtn, CancelBtn }) => (
          <div style={{ textAlign: 'right' }}>
            <Button
              key="submit"
              className="btn-green"
              onClick={() => {
                Modal.destroyAll()
              }}
            >
              Ok
            </Button>
          </div>
        ),
      })
    } catch (e) {
      message.error('Có lỗi xảy ra vui lòng thử lại sau')
    }
  }
  return (
    <Modal
      open={isOpen}
      title="Bạn muốn thiết lập lại mật khẩu?"
      okButtonProps={{
        className: 'btn-green',
      }}
      cancelButtonProps={{
        size: 'large',
      }}
      okText="Xác nhận"
      cancelText="Hủy"
      onOk={resetPasswordFunc}
      onCancel={handleCloseModal}
      centered
    >
      Mật khẩu của tài khoản được chọn sẽ được thiết lập lại về mật khẩu mặc
      định
    </Modal>
  )
}

export default ModalResetPassword
