/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Modal, Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'

const { Countdown } = Statistic

const ModalOTPInput = ({
  isOpen,
  setIsOpen,
  last4digit,
  isResendable,
  setIsResendable,
  getOTP,
  otp,
  setOtp,
  confirmOtp,
  expired_at,
}) => {
  const [deadline, setDeadline] = useState(new Date(expired_at))

  useEffect(() => {
    setDeadline(expired_at)
  }, [expired_at])

  useEffect(() => {
    if (isOpen) {
      setOtp('')
      setIsResendable(false)
      setDeadline(new Date(expired_at))
    }
  }, [isOpen])

  const onFinishCountdown = async () => {
    setIsResendable(true)
  }

  return (
    <Modal
      centered
      open={isOpen}
      wrapClassName="modal-change-password"
      style={{ width: 576 }}
      footer={null}
      closable
      onCancel={() => setIsOpen(false)}
    >
      <div className="modal-content">
        <div className="mcp-header">Quên mật khẩu</div>
        <div className="mcp-sub-header">
          {`Để xác minh danh tính của bạn, chúng tôi đã gửi một mã 6 số${
            last4digit
              ? ` đến số
          điện thoại ***${last4digit}`
              : ''
          }.`}
        </div>
        <div className="mcp-sub-header">
          Vui lòng nhập mã bạn nhận được (hết hạn sau 5 phút) vào ô bên dưới.
          Nếu không nhận được, hãy nhấn
          <span className="fw-bold"> Gửi lại mã</span>
        </div>

        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          onPaste={(event) => {
            const data = event.clipboardData.getData('text')
          }}
          shouldAutoFocus
          inputType="tel"
          containerStyle="otp-container"
          renderInput={(props) => <input {...props} />}
        />

        <div className="btn-update-cont">
          <Button
            size="large"
            type="button"
            className="btn btn-update mt-2"
            disabled={otp?.length !== 6}
            onClick={() => {
              if (otp?.length === 6) {
                confirmOtp(otp)
              }
            }}
          >
            Xác nhận
          </Button>
        </div>

        <div
          className="mcp-sub-header resent-coundown"
          style={{ fontSize: 16, marginTop: 30 }}
        >
          Gửi lại sau
          <span className="counter">
            <Countdown
              value={deadline}
              format="mm:ss"
              onFinish={onFinishCountdown}
            />
          </span>
        </div>
        <div
          className="mcp-sub-header"
          style={{
            fontSize: 24,
            marginTop: 30,
            marginBottom: 30,
            fontWeight: 600,
            cursor: isResendable ? 'pointer' : 'not-allowed',
          }}
        >
          <span
            className={`counter ${isResendable ? '' : 'disabled-counter'}`}
            style={{
              fontSize: 24,
              marginTop: 30,
              marginBottom: 30,
              fontWeight: 600,
            }}
            onClick={() => {
              setOtp('')
              setIsResendable(false)
              getOTP()
            }}
          >
            Gửi lại mã
          </span>
        </div>
      </div>
    </Modal>
  )
}

export default ModalOTPInput
