import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import {
  Table, Tag, Input, Checkbox, Button, message,
} from 'antd'
import FilterDrawerAdmin from './ExternalFilter'
import { DEFAULT_PAGINATE_SIZE } from '../../../constants/config'
import ExternalAccountDrawer from './ExternalAccountDrawer'
import ExternalAccountApis from '../../../apis/externalAccountApis'
import {
  isHasPermission, handlePermission,
} from '../../../redux/reducers/permission'
import { PERMISSION } from '../../../constants/permission'
import { DEFAULT_IMAGE, ROLES_LIST } from '../../../constants/constant'
import { roleExists } from '../../../util'

const getTextTag = {
  suspend: 'Tạm ngừng',
  active: 'Hoạt động',
  pending: 'Chờ duyệt',
  rejected: 'Từ chối',
}

const columns = [
  {
    title: ' ',
    dataIndex: 'checkbox',
    key: 'checkbox',
    render: (text, record) => <Checkbox onClick={(e) => e.stopPropagation()} />,
  },
  {
    title: ' ',
    dataIndex: 'avatar_url',
    key: 'avatar_url',
    render: (avatar) => (
      <img
        src={avatar}
        alt="avatar"
        style={{
          width: 32, height: 32, borderRadius: 4, objectFit: 'contain',
        }}
        onError={(e) => {
          e.target.src = DEFAULT_IMAGE
        }}
      />
    ),
  },
  {
    title: 'Họ và tên',
    dataIndex: 'full_name',
    key: 'full_name',
    ellipsis: true,
  },
  {
    title: 'Tên tài khoản',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'Loại tài khoản',
    dataIndex: 'account_type',
    key: 'account_type',
    align: 'center',
  },
  {
    title: 'Tên seller phụ trách',
    dataIndex: 'seller_name',
    key: 'seller_name',
    ellipsis: true,
    align: 'center',
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY '),
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    render: (item) => (
      <div className="tag-wrapper">
        <div className={`tag tag-${item.toLocaleLowerCase()}`}>
          {getTextTag[item.toLocaleLowerCase()]}
        </div>
      </div>
    ),
  },
]

function ExternalAccount({ isKHDN }) {
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false)
  const [queryParams, setQueryParams] = useState({})
  const [account, setAccount] = useState([])
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const [detailID, setDetailID] = useState(null)
  const [roleList, setRoleList] = useState([])

  const getRoleList = async () => {
    try {
      const res = await ExternalAccountApis.getExternalRoles()
      const mappingData = res?.data?.data?.map?.((item) => ({
        key: item.id,
        value: item.name,
      }))

      setRoleList(mappingData)
      return mappingData
    } catch (error) {
      if (error?.response?.data?.statusCode === 403) {
        message.error('Tính năng không khả dụng')
      } else {
        message.error('Error')
      }
    }
    return []
  }

  const getListExternalAccounts = async () => {
    setLoading(true)
    let newRoleList = [...roleList]
    if (newRoleList.length === 0) {
      newRoleList = await getRoleList()
    }
    const params = {
      limit: DEFAULT_PAGINATE_SIZE,
      order_by: 'created_at',
      sort_by: 'desc',
      page,
      search_text: keyword,
      ...queryParams,
    }
    if (isKHDN && newRoleList.length > 0) {
      params.role_id = newRoleList?.find((x) => x.value === 'Khách hàng doanh nghiệp')?.key
    } else {
      params.without_role_tags = 'business_customer_external_account'
    }
    const res = await ExternalAccountApis.getExternalAccount(params)
    const mappingData = res?.data?.data?.items?.map?.((item) => ({
      ...item,
      account_type: item.roles[0]?.name,
      seller_name: item?.seller?.full_name,
    }))

    setAccount(mappingData)
    setTotal(res?.data?.data?.meta.total)
    setLoading(false)
  }

  useEffect(() => {
    getListExternalAccounts()
  }, [page, keyword, queryParams])

  const paginationConfig = useMemo(
    () => ({
      defaultPageSize: DEFAULT_PAGINATE_SIZE, // Set initial page size
      onChange: (pageNumber, pageSize) => {
        setPage(pageNumber)
      },
      showSizeChanger: false,
      total,
      current: parseInt(page, 10),
    }),
    [total, page],
  )

  const debounceSearch = _.debounce((search) => {
    setKeyword(search)
    setPage(1)
  }, 500)

  const handleSearch = (e) => {
    debounceSearch(e.target.value)
  }

  const openFilterDrawer = () => {
    setIsOpenFilterDrawer(true)
  }

  const closeFilterDrawer = () => {
    setIsOpenFilterDrawer(false)
  }
  // const removeNullAttributes = (obj) => Object.keys(obj).reduce((acc, key) => {
  //   if (obj[key] !== null) {
  //     acc[key] = obj[key]
  //   }
  //   return acc
  // }, {})
  const handleApplyFilter = (filterData) => {
    setQueryParams(filterData)
    setIsOpenFilterDrawer(false)
    setPage(1) // Reset page when applying filters
  }

  useEffect(() => {
    if (!isOpenDrawer) setDetailID(null)
  }, [isOpenDrawer])

  const getDetailAccount = async () => {
    const res = await ExternalAccountApis.getDetailExternalAccount(detailID)
    return res?.data?.data
  }

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      className="external-account-page"
    >
      <div
        className="breadcrumb-bc"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          {isKHDN ? 'Danh sách tài khoản doanh nghiệp' : 'Danh sách tài khoản CTV'}

          <div className="sub-bc">Hiển thị 1-10 của 100 </div>
        </div>
        <div style={{ display: 'flex', gap: 16 }}>
          {/* <button type="button" className="btn-options">
            {optionIcon}
          </button> */}
          {isHasPermission(PERMISSION.CTV_ACCOUNT, handlePermission()) && (
            <Button
              icon={<PlusOutlined />}
              prefixCls="ant-btn-icon-only"
              type="primary"
              className="btn-action-add"
              onClick={() => setIsOpenDrawer(true)}
            >
              Tài khoản mới
            </Button>
          )}
        </div>
      </div>
      <div className="main-content">
        <div className="" style={{ width: '100%', height: '100%' }}>
          <div className="" style={{ display: 'flex', gap: 10 }}>
            <Button
              type="button"
              className="filter-btn"
              onClick={openFilterDrawer}
            >
              <img src="/images/common/filter-list.svg" alt="filter" />
              <p>Bộ lọc</p>
            </Button>
            <div className="search-cont">
              <Input
                placeholder="Search"
                className="search"
                onChange={handleSearch}
              />
              <div className="search-icon-cont">
                <img src="/images/common/search.svg" alt="search" />
              </div>
            </div>
          </div>
          <Table
            className="main-table"
            columns={columns}
            dataSource={account}
            pagination={paginationConfig}
            loading={loading}
            onRow={(record) => ({
              onClick: () => {
                if (!roleExists(ROLES_LIST.CUSTOMER_SERVICE_ACCOUNT)) {
                  setDetailID(record?.id)
                  setIsOpenDrawer(true)
                } else {
                  message.error('Tính năng không khả dụng!')
                }
              },
            })}
          />
        </div>
      </div>
      <FilterDrawerAdmin
        isOpenFilterDrawer={isOpenFilterDrawer}
        closeFilter={closeFilterDrawer}
        queryParams={queryParams}
        onClose={handleApplyFilter}
        roleList={roleList}
        setRoleList={setRoleList}
        isKHDN={isKHDN}
      />
      <ExternalAccountDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        getListExternalAccounts={getListExternalAccounts}
        detailID={detailID}
        getDetailAccountFunction={getDetailAccount}
        isKHDN={isKHDN}
      />
    </div>
  )
}

export default ExternalAccount
