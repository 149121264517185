export const ORDER_PAYMENT_TYPE = {
  pay_all: 'Thanh toán hết',
  deposit: 'Đặt cọc',
}

export const GENDER = {
  female: 'Nữ',
  male: 'Nam',
  other: 'Khác',
}

export const GENDER_LIST = [
  {
    key: 'male',
    value: 'male',
    name: 'Nam',
  },
  {
    key: 'female',
    value: 'female',
    name: 'Nữ',
  },
]

export const ORDER_PAYMENT_STATUS = {
  pending: 'Chờ thanh toán',
  complete: 'Đã thanh toán',
  cancel: 'Hủy thanh toán',
  failed: 'Thanh toán thất bại',
  refund: 'Đang hoàn tiền',
  refund_failed: 'Hoàn tiền thất bại',
  refund_success: 'Đã hoàn tiền',
}

export const ACADEMIC_LEVEL = [
  {
    id: 'HighSchool',
    name: 'THPT',
    value: 'High School',
  },
  {
    id: 'AssociateDegree',
    name: 'Cao đẳng',
    value: 'Associate Degree',
  },
  {
    id: 'BachelorDegree',
    name: 'Đại học',
    value: "Bachelor's Degree",
  },
  {
    id: 'MasterDegree',
    name: 'Thạc sĩ',
    value: "Master's Degree",
  },
  {
    id: 'DoctorateDegree',
    name: 'Tiến sĩ',
    value: 'Doctorate Degree',
  },
  {
    id: 'PostDoctorate',
    name: 'Sau tiến sĩ',
    value: 'Post-Doctorate',
  },
  {
    id: 'Other',
    name: 'Khác',
    value: 'Other',
  },
]

export const COMMISSION_PRODUCT = [
  {
    value: 'sim',
    label: 'Sim',
  },
  {
    value: 'package',
    label: 'Gói mạng',
  },
  {
    value: 'scratchcard',
    label: 'Thẻ cào',
  },
  {
    value: 'm2m_sim',
    label: 'Sim M2M',
  },
  {
    value: 'travel_sim',
    label: 'Sim Du Lịch',
  },
  {
    value: 'packaged_sim',
    label: 'Sim Gói',
  },
  {
    value: 'attractive_sim',
    label: 'Sim Số',
  },
]

export const TYPE_LANDING_PAGE_PRODUCT = [
  'Travel VN',
  'Travel International',
  'M2M',
]

export const DEFAULT_PASSWORD = 'Password@123'

export const PACKAGE_STATUS = {
  unavailable: 'Không có sẵn',
  available: 'Có sẵn',
  sold: 'Đã bán',
}

export const DEFAULT_IMAGE = 'https://statics.simplus.vn/image/f2155c92-08c0-485b-8167-1ef42b6ee04d.jpeg'

export const MY_ROLES = 'MY_ROLES'

export const ROLES_LIST = {
  SUPER_ADMIN: 'super_admin',
  MANAGING_ADMIN: 'managing_admin',
  FINANCE_ADMIN: 'finance_admin',
  CUSTOMER_SERVICE_MANAGER: 'customer_service_manager',
  CUSTOMER_SERVICE_ACCOUNT: 'customer_service_account',
  ADMINISTRATIVE_ACCOUNT: 'administrative_account',
  ADMINISTRATIVE_MANAGER: 'administrative_manager',
  ACCOUNTANT_ACCOUNT: 'accountant_account',
  CHIEF_ACCOUNTANT_ACCOUNT: 'chief_accountant_account', // TRUONG PHONG KE_TOAN
  WAREHOUSE_ACCOUNT: 'warehouse_account',
  BUSINESS_MANAGER: 'business_manager',
  SHIPPER_ACCOUNT: 'shipper_account',
  SELLER_ACCOUNT: 'seller_account',
  EXTERNAL_ACCOUNT: 'external_account',
  AGENT_EXTERNAL_ACCOUNT: 'agent_external_account',
  BUSINESS_CUSTOMER_EXTERNAL_ACCOUNT: 'business_customer_external_account',
  COLLABORATOR_EXTERNAL_ACCOUNT: 'collaborator_external_account',
  RETAIL_CUSTOMER_EXTERNAL_ACCOUNT: 'retail_customer_external_account',
  TECHNICAL_MANAGER: 'technical_manager',
  TECHNICAL_ACCOUNT: 'technical_account',
  MARKETTING_MANAGER: 'marketting_manager',
  MARKETTING_ACCOUNT: 'marketting_account',
  PARTNER_REPRESENTATIVE: 'partner_representative',
}

export const BIG_PROVIDERS_NAME = ['Viettel', 'Mobifone', 'Vinaphone']

export const COLUMNS_UPLOAD_SIM_PACKAGE = [
  {
    title: 'Trạng thái',
    key: 'status',
    fixed: true,
    align: 'center',
  },
  {
    title: 'No',
    key: 'no',
    dataIndex: 'no',
  },
  {
    title: 'Seller ID',
    key: 'seller_id',
    dataIndex: 'seller_id',
  },
  {
    title: 'Số điện thoại',
    key: 'number',
    dataIndex: 'number',
  },
  {
    title: 'Tách số',
    key: 'formated_number',
    dataIndex: 'formated_number',
  },
  {
    title: 'Nhà mạng',
    key: 'provider',
    dataIndex: 'provider',
  },
  {
    title: 'Loại thuê bao',
    key: 'subscriber_type',
    dataIndex: 'subscriber_type',
  },
  {
    title: 'Giá thu',
    key: 'buy_price',
    dataIndex: 'buy_price',
  },
  {
    title: 'Giá niêm yết',
    key: 'origin_price',
    dataIndex: 'origin_price',
  },
  {
    title: 'Giá bán',
    key: 'sell_price',
    dataIndex: 'sell_price',
  },
  {
    title: 'Serial',
    key: 'serial',
    dataIndex: 'serial',
  },
  {
    title: 'Gói cước (SimPlus)',
    key: 'package',
    dataIndex: 'package',
  },
  {
    title: 'Hoa hồng',
    key: 'commission',
    dataIndex: 'commission',
  },
  {
    title: 'Thời gian CK (Tháng)',
    key: 'period',
    dataIndex: 'period',
  },
  {
    title: 'Số tiền CK',
    key: 'price_period',
    dataIndex: 'price_period',
  },
  {
    title: 'Ngày đấu sim',
    key: 'sim_match_day',
    dataIndex: 'sim_match_day',
  },
  {
    title: 'Ngày lên gói',
    key: 'pack_up_date',
    dataIndex: 'pack_up_date',
  },
  {
    title: 'Ngày kích hoạt',
    key: 'active_date',
    dataIndex: 'active_date',
  },
  {
    title: 'Trạng thái',
    key: 'product_status',
    dataIndex: 'product_status',
  },
  {
    title: 'Note',
    key: 'note',
    dataIndex: 'note',
  },
]

export const PRODUCT_CATEGORY_ID = '572bdc41-91a8-4766-b5e0-2a62ca06b573'

export const ACCESSORY_UNIT = [
  {
    key: 'piece',
    value: 'Chiếc',
  },
  {
    key: 'pair',
    value: 'Cặp',
  },
  {
    key: 'pack',
    value: 'Bộ',
  },
]

export const PACKAGED_SIM_STATUS = [
  'Đã kích',
  'Đóng kit',
  'Đóng băng',
  'Chặn 1C',
  'Chặn 2C',
  'Hủy',
  'NKXK',
  'Lỗi - Chờ xử lý',
]

export const UPLOAD_SIM_STATUS = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  CANCEL: 'canceled',
}

export const EXCEL_SIM_SO = 'https://statics.simplus.vn/excel/e0af4674-fd9e-4ea1-ad1d-c2f38106cc5e.xlsx'
export const EXCEL_SIM_GOI = 'https://statics.simplus.vn/excel/d3a81d3e-9557-4aec-bd00-2888daba9eff.xlsx'
export const EXCEL_SIM_DL = 'https://statics.simplus.vn/excel/7a46df05-98bd-4551-a754-264740611e23.xlsx'

export const EXCEL_SIM_SO_PARNTER = 'https://statics.simplus.vn/excel/103353bd-8cb1-4946-b533-688c7b336ebc.xlsx'
export const EXCEL_SIM_GOI_PARNTER = 'https://statics.simplus.vn/excel/c8f150b9-d1ca-4c73-b022-f65746df9c76.xlsx'
export const EXCEL_SIM_DL_PARNTER = 'https://statics.simplus.vn/excel/f453a906-d58f-406e-8375-e89b0ccf5aee.xlsx'

export const EXTEND_SIM_STATUS = {
  Pending: {
    key: 'Pending',
    value: 'Chờ xử lý',
  },
  Approve: {
    key: 'Approve',
    value: 'Đồng ý gia hạn',
  },
  Reject: {
    key: 'Reject',
    value: 'Từ chối gia hạn',
  },
  Success: {
    key: 'Success',
    value: 'Gia hạn thành công',
  },
  Fail: {
    key: 'Fail',
    value: 'Gia hạn thất bại',
  },
}

export const ROLE_CSKH = [
  ROLES_LIST.CUSTOMER_SERVICE_ACCOUNT,
  ROLES_LIST.CUSTOMER_SERVICE_MANAGER,
]

export const ROLE_TECHNICAL = [
  ROLES_LIST.TECHNICAL_ACCOUNT,
  ROLES_LIST.TECHNICAL_MANAGER,
]

export const ROLE_ADMIN = [
  ROLES_LIST.SUPER_ADMIN,
  ROLES_LIST.FINANCE_ADMIN,
  ROLES_LIST.MANAGING_ADMIN,
]

const DEFAULT_QUERY_EXTEND = {
  page: 1,
  cskh_ids: null,
  cskh_null: null,
  technical_account_ids: null,
  technical_account_null: null,
  start_at: null,
  end_at: null,
  status: 'all',
}

export const DEFAULT_QUERY_EXTEND_SIM_ATTRACTIVE = {
  ...DEFAULT_QUERY_EXTEND,
  sim_type: 'attractive_sim',
}

export const DEFAULT_QUERY_EXTEND_SIM_PACKAGE = {
  ...DEFAULT_QUERY_EXTEND,
  sim_type: 'packaged_sim',
}

export const DEFAULT_QUERY_EXTEND_SIM_M2M = {
  ...DEFAULT_QUERY_EXTEND,
  sim_type: 'm2m_sim',
}

export const EXCEL_FILE_TYPE = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

export const ORDER_STATUS = {
  pending: {
    name: 'Chờ xác nhận',
    nextStatus: ['approved', 'cancelled'],
  },
  approved: {
    name: 'Đã xác nhận',
    nextStatus: ['release_confirmed', 'release_cancelled'],
  },
  cancelled: {
    name: 'Hủy đơn',
    nextStatus: [],
  },
  release_confirmed: {
    name: 'Xác nhận xuất hàng',
    nextStatus: ['registered', 'register_cancelled'],
  },
  release_cancelled: {
    name: 'Huỷ xuất hàng',
    nextStatus: [],
  },
  registered: {
    name: 'Đã đăng ký',
    nextStatus: ['delivered', 'deliver_failed'],
  },
  register_cancelled: {
    name: 'Huỷ đăng ký',
    nextStatus: ['release_cancelled'],
  },
  deliver_failed: {
    name: 'Giao hàng thất bại',
    nextStatus: ['register_cancelled'],
  },
  delivered: {
    name: 'Đã giao hàng',
    nextStatus: [],
  },
  refund_success: {
    name: 'Đã hoàn tiền',
    nextStatus: [],
  },
  refund_failed: {
    name: 'Hoàn tiền thất bại',
    nextStatus: [],
  },
  refund: {
    name: 'Đang hoàn tiền',
    nextStatus: [],
  },
  // initialize: {
  //   name: 'Khởi tạo',
  //   nextStatus: ['pending'],
  // },

  initialize_failed: {
    name: 'Mua hàng lỗi',
    nextStatus: [],
  },
}

export const BONUS_POINT_ACTION = {
  // enum ECommissionTransactionType {
  //   EDIT_POINT = 'edit_point',
  //   ADMIN_ADD_POINT = 'admin_add_point',
  //   ADMIN_SUBTRACT_POINT = 'admin_subtract_point',
  //   WITHDRAW_POINT = 'withdraw_point',
  //   ADD_POINT = 'add_point',
  // }
  edit_point: {
    name: 'Khác',
    prefix: '+',
    text_color_class: 'text-green',
  },
  admin_add_point: {
    name: 'Khác',
    prefix: '+',
    text_color_class: 'text-green',
  },
  admin_subtract_point: {
    name: 'Khác',
    prefix: '-',
    text_color_class: 'text-red',
  },
  withdraw_point: {
    name: 'Đổi điểm',
    prefix: '', // Không hiển thị dấu trong status này
    text_color_class: '', // Màu mặc định trong status này
  },
  add_point: {
    name: 'Hoa hồng bán hàng',
    prefix: '+',
    text_color_class: 'text-green',
  },
}

export const BONUS_POINT_STATUS = {
  // export enum ECommissionTransactionStatus {
  //   PENDING = 'pending',
  //   SUCCESS = 'success',
  //   CANCEL = 'cancel',
  // }
  pending: 'Chờ duyệt',
  success: 'Thành công',
  cancel: 'Không duyệt',
}

export const regexPhoneNumber = /^(0?)(3[2-9]|(52|55|56|58)|7[0|6-9]|8[0-9]|9[0-4|6-8])[0-9]{7}$|(016|012)[0-9]{8}$/

export const newRegexPhoneNumber = /^(?:\+84|0)(3[2-9]|5[2|5|6|8]|7[0|6-9]|8[0-9]|9[0-4|6-8])[0-9]{7}$/

export const BLOG_STATUS_ENUM = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  SHOW: 'Show',
  HIDE: 'Hide',
}

export const BLOG_STATUS_TEXT = {
  DRAFT: 'Nháp',
  PENDING: 'Chờ duyệt',
  APPROVE: 'Đã duyệt',
  REJECT: 'Từ chối',
  SHOW: 'Đang chạy',
  HIDE: 'Đang tắt',
  ALL: 'Tất cả',
}

export const BLOG_STATUS_FILTER = [
  'all',
  'Draft',
  'Pending',
  'Show',
  'Hide',
  'Reject',
]

export const FLASHSALE_TYPE = {
  SIM: 'Sim',
  ACCESSORY: 'Accessory',
}

export const PAYMENT_TYPE = {
  '8c9a29b4-f7be-49ca-9787-f1d1843ac225': 'VNPay',
  'a4556f26-364f-430f-bcb0-f45e9313d0da': 'Momo',
  'e50e4963-7927-4d7f-a966-0bc2c6b9213f': 'Chuyển khoản ngân hàng',
  '34e31163-bf56-4116-a4ca-5ce1dfba6b93': 'Đổi điểm',
}

export const PRODUCT_TYPE = {
  packaged_sim: 'Sim Gói',
  attractive_sim: 'Sim Số',
  travel_sim: 'Sim Du Lịch',
  m2m_sim: 'Sim M2M',
}

export const COUNTUP_DURATION = 3
