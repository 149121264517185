import { configureStore } from '@reduxjs/toolkit'
import Permission from './reducers/permission'

const store = configureStore({
  reducer: {
    permission: Permission,
  },
})

export default store
