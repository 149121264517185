import Axios from '../services/Axios'

export function loginWithUsername(params) {
  return Axios.getInstance().post('/api/v1/login', {
    params,
  })
}

export function changePassword(params) {
  return Axios.getInstance().put('/api/v1/accounts/change-password', {
    ...params,
  })
}

export function forgotAndChangePassword(params) {
  return Axios.getInstance().post('/api/v1/accounts/forgot-password', {
    ...params,
  })
}
