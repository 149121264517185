import { Button } from 'antd'
import { SaveFilled } from '@ant-design/icons'

const ButtonSave = ({ onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }
  return <Button className="button-save" type="ghost" icon={<SaveFilled />} onClick={handleClick}>Lưu lại</Button>
}

export default ButtonSave
