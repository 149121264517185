/* eslint-disable prefer-promise-reject-errors */
import { useEffect, useState } from 'react'
import {
  Checkbox, Form, Input, Modal, message,
} from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { correctIcon, incorrectIcon } from '../../constants/svg'
import { changePassword } from '../../apis/loginApis'
import { DEFAULT_PASSWORD } from '../../constants/constant'

function checkRegexMatch(inputString, regexPattern) {
  const regex = new RegExp(regexPattern)
  return regex.test(inputString)
}

function checkValidate(type, password, retypePassword) {
  if (type === 'upperCase') {
    const uppercaseRegex = /^(?=.*[A-Z])/
    if (checkRegexMatch(password, uppercaseRegex)) return true
  }
  if (type === 'special') {
    const specialCharacterRegex = /^(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~])/
    if (checkRegexMatch(password, specialCharacterRegex)) return true
  }
  if (type === 'digit') {
    const digitRegex = /^(?=.*\d)/
    if (checkRegexMatch(password, digitRegex)) return true
  }
  if (type === 'minLength') {
    const minLengthRegex = /^.{8,}$/
    if (checkRegexMatch(password, minLengthRegex)) return true
  }
  if (type === 'match') {
    return password !== DEFAULT_PASSWORD
  }
  return false
}

const getValidateIcon = (type, password, retypePassword) => {
  if (checkValidate(type, password, retypePassword)) return correctIcon
  return incorrectIcon
}

const ModalChangePassword = ({ isOpen, setIsOpen, onSuccess }) => {
  const [form] = Form.useForm()
  const [password, setPassword] = useState('')
  const [retypePassword, setRetypePassword] = useState('')
  const [isValidate, setIsValidate] = useState(false)

  const handleClickUpdate = async () => {
    form.validateFields()
    setIsValidate(true)
    const allValidate = checkValidate('upperCase', password, retypePassword)
      && checkValidate('special', password, retypePassword)
      && checkValidate('digit', password, retypePassword)
      && checkValidate('minLength', password, retypePassword)
      && checkValidate('match', password, retypePassword)
    if (allValidate) {
      if (password === DEFAULT_PASSWORD) {
        message.error('Mật khẩu mới không được trùng với mật khẩu mặc định')
        return
      }

      try {
        const res = await changePassword({
          current_password: DEFAULT_PASSWORD,
          new_password: password,
          confirm_password: retypePassword,
        })
        if (res?.data?.data) {
          onSuccess()
        }
      } catch (e) {
        message.error('Có lỗi xảy ra vui lòng thử lại')
      }
    }
  }

  const onHandleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'password') {
      setPassword(value)
    }
    if (name === 'retype_password') {
      setRetypePassword(value)
    }
  }

  return (
    <Modal
      centered
      open={isOpen}
      wrapClassName="modal-change-password"
      style={{ width: 576 }}
      footer={null}
      closeIcon={null}
      closable={false}
      //   onCancel={() => setIsOpen(false)}
    >
      <div className="modal-content">
        <div className="mcp-header">Đổi mật khẩu mới</div>
        <div className="mcp-sub-header">
          Bạn đang sử dụng mật khẩu mặc định. Vui lòng đổi mật khẩu
        </div>
        <div className="mcp-form">
          <Form
            layout="vertical"
            form={form}
            // onFinish={handleFinish}
          >
            <Form.Item label="Mật khẩu mới" name="password">
              <Input.Password
                iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                name="password"
                placeholder="Nhập mật khẩu mới"
                onChange={onHandleChange}
              />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="retype_password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (password === retypePassword) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Mật khẩu không khớp')
                  },
                }),
              ]}
            >
              {' '}
              <Input.Password
                iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                name="retype_password"
                placeholder="Nhập lại mật khẩu mới"
                onChange={onHandleChange}
              />
            </Form.Item>
          </Form>
          <div>
            <div className="text-checklist">Mật khẩu cần bao gồm:</div>
            <ul className={`cp-checklist ${isValidate ? 'remove-stype' : ''}`}>
              <li>
                {isValidate && (
                  <>{getValidateIcon('upperCase', password, retypePassword)}</>
                )}
                Ít nhất một ký tự viết hoa
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('special', password, retypePassword)}</>
                )}
                Ít nhất một ký tự viết đặc biệt
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('digit', password, retypePassword)}</>
                )}
                Ít nhất một chữ số
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('minLength', password, retypePassword)}</>
                )}
                Tối thiểu 8 ký tự
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('match', password, retypePassword)}</>
                )}
                Không trùng với mật khẩu mặc định
              </li>
            </ul>
          </div>
          <div className="btn-update-cont">
            <button
              onClick={handleClickUpdate}
              type="button"
              className="btn btn-update"
            >
              Cập nhật mật khẩu
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalChangePassword
