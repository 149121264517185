/* eslint-disable prefer-promise-reject-errors */
import { useEffect, useMemo, useState } from 'react'
import {
  Button, Form, Input, Modal, message,
} from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { correctIcon, incorrectIcon } from '../../constants/svg'
import { changePassword, forgotAndChangePassword } from '../../apis/loginApis'

function checkRegexMatch(inputString, regexPattern) {
  const regex = new RegExp(regexPattern)
  return regex.test(inputString)
}

function checkValidate(type, password, retypePassword) {
  if (type === 'upperCase') {
    const uppercaseRegex = /^(?=.*[A-Z])/
    if (checkRegexMatch(password, uppercaseRegex)) return true
  }
  if (type === 'special') {
    const specialCharacterRegex = /^(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~])/
    if (checkRegexMatch(password, specialCharacterRegex)) return true
  }
  if (type === 'digit') {
    const digitRegex = /^(?=.*\d)/
    if (checkRegexMatch(password, digitRegex)) return true
  }
  if (type === 'minLength') {
    const minLengthRegex = /^.{8,}$/
    if (checkRegexMatch(password, minLengthRegex)) return true
  }
  return false
}

const getValidateIcon = (type, password, retypePassword) => {
  if (checkValidate(type, password, retypePassword)) return correctIcon
  return incorrectIcon
}

const ModalChangeAndForgotPassword = ({
  isOpen,
  setIsOpen,
  isForgot,
  handleLogout,
  dataForgotPassword,
  setDataForgotPassword,
  setIsOpenModalOtp,
}) => {
  const [form] = Form.useForm()
  const [password, setPassword] = useState('')
  const [retypePassword, setRetypePassword] = useState('')
  const [isValidate, setIsValidate] = useState(false)

  useEffect(() => {
    if (isOpen) {
      form.resetFields()
      setIsValidate(false)
    }
    // else {
    //   setDataForgotPassword(null)
    // }
  }, [isOpen])

  const allValidate = useMemo(
    () => checkValidate('upperCase', password, retypePassword)
      && checkValidate('special', password, retypePassword)
      && checkValidate('digit', password, retypePassword)
      && checkValidate('minLength', password, retypePassword)
      && password === retypePassword,
    [password, retypePassword],
  )

  const handleClickUpdate = async () => {
    if (allValidate) {
      try {
        if (isForgot) {
          const res = await forgotAndChangePassword({
            ...dataForgotPassword,
            password,
            confirm_password: retypePassword,
          })
        } else {
          const res = await changePassword({
            current_password: form.getFieldValue('current_password'),
            new_password: password,
            confirm_password: retypePassword,
          })
        }
        setIsOpen(false)
        Modal.success({
          title: 'Thành công',
          content: 'Đổi mật khẩu thành công',
          okText: 'Đóng',
          centered: true,
          okButtonProps: {
            style: {
              backgroundColor: 'transparent',
              color: 'black',
              borderColor: 'rgba(232, 232, 232, 1)',
              marginTop: 15,
            },
          },
          onOk: () => {
            if (!isForgot) {
              handleLogout()
            } else {
              setIsOpen(false)
              setIsOpenModalOtp(false)
            }
          },
          onCancel: () => {
            if (!isForgot) {
              handleLogout()
            } else {
              setIsOpen(false)
              setIsOpenModalOtp(false)
            }
          },
        })
      } catch (e) {
        const errorMessage = e?.response?.data?.message
        let error = 'Có lỗi xảy ra. Vui lòng thử lại sau'
        if (errorMessage?.includes('E10139')) {
          error = 'Mật khẩu hiện tại không đúng. Vui lòng nhập lại'
        }
        Modal.error({
          title: 'Cập nhật mật khẩu thất bại',
          content: error,
          okText: 'Đóng',
          centered: true,
          okButtonProps: {
            style: {
              backgroundColor: 'transparent',
              color: 'black',
              borderColor: 'rgba(232, 232, 232, 1)',
              marginTop: 15,
            },
          },
        })
      }
    }
  }

  const onHandleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    if (name === 'password') {
      setPassword(value)
    }
    if (name === 'retype_password') {
      form.validateFields(['retype_password'])
      setRetypePassword(value)
    }
  }

  return (
    <Modal
      centered
      open={isOpen}
      wrapClassName="modal-change-password"
      style={{ width: 576 }}
      footer={null}
      closable
      onCancel={() => setIsOpen(false)}
    >
      <div className="modal-content">
        <div className="mcp-header">
          {`Đổi mật khẩu${isForgot ? ' mới' : ''}`}
        </div>
        <div className="mcp-sub-header">
          Vui lòng nhập mật khẩu mới, ghi nhớ và không tiết lộ cho ai
        </div>
        <div className="mcp-form">
          <Form
            onChange={() => setIsValidate(true)}
            layout="vertical"
            form={form}
            onFinish={handleClickUpdate}
          >
            {!isForgot && (
              <Form.Item
                label="Mật khẩu hiện tại"
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập mật khẩu hiện tại',
                  },
                ]}
              >
                <Input.Password
                  iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                  name="current_password"
                  placeholder="Nhập mật khẩu hiện tại"
                  onChange={onHandleChange}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Mật khẩu mới"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu mới',
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                name="password"
                placeholder="Nhập mật khẩu mới"
                onChange={onHandleChange}
              />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              name="retype_password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (password === retypePassword) {
                      return Promise.resolve()
                    }
                    return Promise.reject('Mật khẩu không khớp')
                  },
                }),
              ]}
            >
              {' '}
              <Input.Password
                iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                name="retype_password"
                placeholder="Nhập lại mật khẩu mới"
                onChange={onHandleChange}
              />
            </Form.Item>
          </Form>
          <div>
            <div className="text-checklist">Mật khẩu cần bao gồm:</div>
            <ul className={`cp-checklist ${isValidate ? 'remove-stype' : ''}`}>
              <li>
                {isValidate && (
                  <>{getValidateIcon('upperCase', password, retypePassword)}</>
                )}
                Ít nhất một ký tự viết hoa
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('special', password, retypePassword)}</>
                )}
                Ít nhất một ký tự viết đặc biệt
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('digit', password, retypePassword)}</>
                )}
                Ít nhất một chữ số
              </li>
              <li>
                {isValidate && (
                  <>{getValidateIcon('minLength', password, retypePassword)}</>
                )}
                Tối thiểu 8 ký tự
              </li>
            </ul>
          </div>
          <div className="btn-update-cont">
            <Button
              disabled={!allValidate}
              size="large"
              onClick={() => form.submit()}
              type="button"
              className="btn btn-update"
            >
              Cập nhật mật khẩu
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalChangeAndForgotPassword
