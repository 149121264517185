import { useEffect, useState } from 'react'

function useDebounce(keyword, delay) {
  const [result, setResult] = useState(keyword)

  useEffect(() => {
    const handler = setTimeout(() => {
      setResult(keyword)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [keyword])

  return result
}

export default useDebounce
